import React, { useEffect, useState } from "react";

const LocationPermissionComponent: React.FC = () => {
  const [locationPermission, setLocationPermission] = useState<boolean | null>(
    null
  );

  const askForLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // User granted permission
        console.log("User granted location permission", position);
        setLocationPermission(true);
      },
      (error) => {
        // alert('sds');
        // User denied permission or there was an error
        console.error("Error getting location:", error);
        // alert(`Error accessing location: ${error.message}`);
        setLocationPermission(false);
      }
    );
  };

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      askForLocationPermission();
    } else {
      // Geolocation is not supported
      console.error("Geolocation is not supported by your browser.");
      setLocationPermission(false);
    }
  }, []);

  if (locationPermission === null) {
    // Waiting for permission response
    return <center>Requesting location permission...</center>;
  }

  if (locationPermission === false) {
    // Permission denied or error
    return <center>Location permission denied or not available.</center>;
  }

  // Permission granted, you can render your component or fetch data based on location
  return <div>{/* Your component or logic based on location goes here */}</div>;
};

export default LocationPermissionComponent;
