import axios from "axios";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import { useLocation } from "../contexts/LocationContext";
import { GoogleMapsContext } from "../contexts/GoogleMapsContext"; // Example import

interface SearchProps {
  onSearch: (query: string) => void;
  onSelectLocation: (location: SelectedPlace) => void;
  UserPlace: any;
}

type SelectedPlace = {
  lat: number;
  lng: number;
  address: {
    label: string;
  };
};

const SearchIcon: React.FC = () => {
  return (
    <svg
      className="search-icon"
      style={{
        cursor: "pointer",
        width: "20px",
        height: "20px",
      }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  );
};

const ClearIcon: React.FC<{ onClick: any }> = ({ onClick }) => {
  return (
    <svg
      fill="#000000"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
        width: "20px",
        height: "20px",
      }}
      onClick={onClick}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M 27.9999 51.9063 C 41.0546 51.9063 51.9063 41.0781 51.9063 28 C 51.9063 14.9453 41.0312 4.0937 27.9765 4.0937 C 14.8983 4.0937 4.0937 14.9453 4.0937 28 C 4.0937 41.0781 14.9218 51.9063 27.9999 51.9063 Z M 19.5858 38.4063 C 18.4843 38.4063 17.5936 37.5156 17.5936 36.4141 C 17.5936 35.8750 17.8280 35.4063 18.2030 35.0547 L 25.1874 28.0234 L 18.2030 20.9922 C 17.8280 20.6641 17.5936 20.1719 17.5936 19.6328 C 17.5936 18.5547 18.4843 17.6875 19.5858 17.6875 C 20.1249 17.6875 20.5936 17.8984 20.9452 18.2734 L 27.9765 25.2812 L 35.0546 18.25 C 35.4530 17.8281 35.8749 17.6406 36.3905 17.6406 C 37.4921 17.6406 38.3827 18.5312 38.3827 19.6094 C 38.3827 20.1484 38.1952 20.5937 37.7968 20.9688 L 30.7655 28.0234 L 37.7733 35.0078 C 38.1249 35.3828 38.3593 35.8516 38.3593 36.4141 C 38.3593 37.5156 37.4687 38.4063 36.3671 38.4063 C 35.8046 38.4063 35.3358 38.1719 34.9843 37.8203 L 27.9765 30.7890 L 20.9921 37.8203 C 20.6405 38.1953 20.1249 38.4063 19.5858 38.4063 Z"></path>
      </g>
    </svg>
  );
};

const Search: React.FC<SearchProps> = ({
  onSearch,
  onSelectLocation,
  UserPlace,
}) => {
  const [inputText, setInputText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const [predictions, setPredictions] = useState<SelectedPlace[]>([]);
  const { selectedLocationSaved, setSelectedLocationSaved } = useLocation();
  const { locationParam } = useParams(); // Use the interface here
  const navigate = useNavigate(); // Use the hook here
    // Use Google Maps context to access the Google Maps API object
    const { geocoder } = useContext(GoogleMapsContext); // Use Google Maps context

  useEffect(() => {
    if (geocoder) {
      setAutocompleteService(new google.maps.places.AutocompleteService());
    }
    if (selectedLocationSaved !== null) {
      setInputText(selectedLocationSaved.address.label);
      setIsSearching(true);
    }

    let location;
    // Decode and parse the location JSON
    if (locationParam) {
      try {
        // Decode the URI component and parse it back into an object
        location = JSON.parse(decodeURIComponent(locationParam));
        console.log(location); // Now location is an object with lat, lng, and label
      } catch (error) {
        console.error("Error parsing location data:", error);
        // Handle parsing errors, possibly by showing an error message or setting a default state
      }
    }

    if (location) {
      // Fetch data based on 'location' or handle it as needed
      setInputText(location.address.label);
    }
  }, [selectedLocationSaved, geocoder]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setInputText(query);
    onSearch(query);
    setIsSearching(true);

    if (autocompleteService) {
      autocompleteService.getPlacePredictions(
        { input: query },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const suggestedPlaces = predictions.map((prediction) => ({
              lat: 0, // Latitude (not available in predictions)
              lng: 0, // Longitude (not available in predictions)
              address: {
                label: prediction.description,
              },
            }));
            setPredictions(suggestedPlaces);
          } else {
            setPredictions([]);
          }
        }
      );
    }
  };

  const handleSelectPrediction = async (selectedPrediction: SelectedPlace) => {
    setInputText(selectedPrediction.address.label);
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        selectedPrediction.address.label
      )}&key=` + process.env.REACT_APP_GOOGLE_MAP_API_KEY
    );

    const { results } = response.data;

    if (results && results.length > 0) {
      const { lat, lng } = results[0].geometry.location;
      // setCoordinates({ lat, lng });
      console.log("results", lat);
      onSelectLocation({
        lat,
        lng,
        address: {
          label: selectedPrediction.address.label,
        },
      });
    } else {
      onSelectLocation({
        lat: 0,
        lng: 0,
        address: {
          label: "",
        },
      });

      // Handle case when no results found
      // setCoordinates(null);
    }

    setPredictions([]);
  };

  return (
    <div className="container-big">
      <section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-8">
            <div className="text-center">
              <h1 className="title-text mt-40">
                Find a Pickleball game near you
              </h1>
              <div className="mt-32 position-relative">
                <div className="search-container">
                  <SearchIcon />
                  <input
                    className="search-input-custom"
                    type="text"
                    placeholder="Search by city, facility or address"
                    value={inputText}
                    onChange={handleInputChange}
                  />
                  {/* Search icon or button */}
                  {isSearching && (
                    <ClearIcon
                      onClick={() => {
                        setInputText("");
                        setIsSearching(false);
                        onSelectLocation(
                          UserPlace ?? {
                            lat: 43.6532, // Toronto's approximate latitude
                            lng: -79.3832, // Toronto's approximate longitude
                            address: {
                              label: "Toronto",
                            },
                          }
                        );
                        setSelectedLocationSaved(null);
                        setPredictions([]);
                        navigate("/"); // Navigate to root when "X" is clicked
                      }}
                    />
                  )}
                </div>
                {predictions.length > 0 && (
                  <ul className="suggestions-list">
                    {predictions.map((prediction, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectPrediction(prediction)}
                      >
                        {prediction.address.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Search;
