import React, { useState, useEffect } from "react";
import { ThunkAction } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { GetEventByID } from "../../store/actions/events";
import { useParams, NavLink } from "react-router-dom";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { RootState } from "store/reducers";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MoreEvents from "layout/MoreEvents";
// import GoogleMapReact from 'react-google-maps-api';
const MapMarker = ({ text }: { text: string }) => (
  <div style={{ position: 'relative', cursor: 'pointer' }}>
    <img src="/images/pickle.svg" alt="Location Pin" style={{ width: '30px', height: '30px' }} />
    {/* <div>
      {text}
    </div> */}
  </div>
);
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  // Split the ISO 8601 date string
  const [year, month, day] = dateString.split("-");

  // Construct the date using the ISO 8601 format
  const formattedDate = new Date(`${year}-${month}-${day}`).toLocaleDateString(
    "en-US",
    options
  );

  return formattedDate;
};

const formatTimeRange = (startTime: string, endTime: string) => {
    // Check if startTime and endTime are valid strings
    if (typeof startTime !== 'string' || typeof endTime !== 'string') {
      return 'Invalid time format';
    }
  const formatTime = (time: string) => {
    // Extracting only the time part from the datetime string
    const timePart = time.split("T")[1].split(":").slice(0, 2).join(":");
    const [hour, minute] = timePart.split(":");

    // Determining whether it's AM or PM
    const period = parseInt(hour, 10) < 12 ? "am" : "pm";

    // Formatting hours to 12-hour format
    const formattedHour = parseInt(hour, 10) % 12 || 12;

    // Returning formatted time
    return `${formattedHour}:${minute}${period}`;
  };

  // Formatting start and end times and combining them
  return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};


const EventDetails = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [mapKey, setMapKey] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [lat, setLat] = useState(0); // Set default values if needed
  const [lng, setLng] = useState(0); // Set default values if needed

  const eventDetails = useSelector((state: RootState) => state.eventManagement);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const [searchInput, setSearchInput] = useState("");
  const [copied, setCopied] = useState(false);
  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 200000);
        console.log("Text successfully copied to clipboard:", textToCopy);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  };

  useEffect(() => {
    const fetchEventById = async () => {
      try {
        if (eventId) {
          // alert(eventId);
          const eventDetails = await GetEventByID(eventId); // Replace with your API call function
          const successCallback = () => {
            setIsDataLoaded(true);
            // Increment the mapKey to trigger GoogleMapReact re-render
            setMapKey((prevKey) => prevKey + 1);
          };

          dispatch(GetEventByID(eventId, successCallback));
        }
      } catch (error) {
        console.error("Error fetching event by ID:", error);
      }
    };

    fetchEventById();
  }, [dispatch, eventId]);

  const geocode = eventDetails.events.geocode;
  const add_info = eventDetails?.events?.add_info ?? "No Description";
  const getDefaultCenter = () => {
    if (geocode) {
      return {
        lat: parseFloat(geocode.split(",")[0]),
        lng: parseFloat(geocode.split(",")[1]),
      };
    } else {
      // If geocode is not available, set default coordinates for Canada
      return {
        lat: 43.6623976, // Default latitude for Canada
        lng: -79.4202985, // Default longitude for Canada
      };
    }
  };
  return (
    <>
      <Header />
      <div className="container-mid">
        <section className="facility">
          <div className="banner-image">
            {eventDetails?.events?.image?.length > 0 ? (
              <img
                src={eventDetails.events.image[0]["img"]}
                alt="Event Image"
              />
            ) : eventDetails?.events?.facility_info?.image?.length > 0 ? (
              <img
                src={eventDetails.events.facility_info.image[0]["img"]}
                alt="Facility Image"
              />
            ) : (
              <img src="/images/Rectangle-9.jpg" alt="Default Image" />
            )}
          </div>
          <h2 className="font-green-small">
            <div className="d-flex flex-column justify-content-between">
              {formatDate(eventDetails?.events?.start_date ?? Date())} •{" "}
              {formatTimeRange(eventDetails?.events?.start_time ?? 0, eventDetails?.events?.end_time ?? 0)}
            </div>
          </h2>
          <h2 className="midium-text">{eventDetails.events.name}</h2>
          <div className="event-card-info">
            <div className="d-flex justify-content-between flex-column">
              <div className="d-flex align-items-left flex-column">
                <div className="mutated-text-14">
                  {eventDetails.events.location}
                </div>
                <div className="mutated-text-14">
                  • {Math.floor(eventDetails.events.distance)}km away
                </div>
              </div>
            </div>
            <div className="d-flex mt-4">
              {eventDetails?.events?.facility_info && (
                <NavLink
                  className="border-green centered-button"
                  to={`/facility-details/${eventDetails?.events?.id}`}
                  key={eventDetails?.events?.id}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  View Facility
                </NavLink>
              )}
            </div>
          </div>
          <div className="border-div"></div>
        </section>
        <section className="facility p-1">
          <div className="row">
            <div className="col-12 col-md-7 mb-3">
              <div>
                <h5 className="font-blue-20">About this event</h5>
                {showMore ? (
                  <span>
                    {add_info}
                    <span
                      onClick={toggleShowMore}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <p
                        className="mutated-text-14-bold a"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Show Less
                      </p>
                    </span>
                  </span>
                ) : (
                  <span>
                    {add_info.slice(0, 300)}{" "}
                    {/* Display only the first 3 lines */}
                    {add_info.length > 300 && (
                      <span
                        onClick={toggleShowMore}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <p
                          className="mutated-text-14-bold a"
                          style={{ color: "blue", textDecoration: "underline" }}
                        >
                          Show more
                        </p>
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div className="border-div"></div>
              <div>
                <h5 className="font-blue-20">Location</h5>

                <div id="map" className="map-div">
                  <GoogleMapReact
                    key={mapKey}
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                    }}
                    defaultCenter={getDefaultCenter()}
                    zoom={15}
                  >
                    <MapMarker
                      text={eventDetails.events?.name ?? "Event Location"}
                    />
                  </GoogleMapReact>
                </div>
                <div className="mt-4 d-flex">
                  <div className="mutated-text-16 location-fill-details p-lg-0">
                    {eventDetails?.events?.location ?? ""}
                  </div>
                  {/* <div
                    className="copy-text pt-1 px-xl-2"
                    onClick={() =>
                      copyToClipboard(eventDetails.events.location)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M13.7891 9V13.5C13.7891 13.7652 13.6837 14.0196 13.4962 14.2071C13.3086 14.3946 13.0543 14.5 12.7891 14.5H3.78906C3.52385 14.5 3.26949 14.3946 3.08196 14.2071C2.89442 14.0196 2.78906 13.7652 2.78906 13.5V9M8.28906 1.5V13M3.28906 6.5L7.93906 1.85C8.03253 1.75839 8.15819 1.70707 8.28906 1.70707C8.41994 1.70707 8.5456 1.75839 8.63906 1.85L13.2891 6.5"
                        stroke="#222222"
                      />
                    </svg>
                    &nbsp;
                    {copied && (
                      <span className="pb-1" style={{ color: "#00A50E", fontSize: "14px",marginTop:"-4px" }}> Copied url</span>
                    )}
                  </div> */}
                </div>
                <div className="d-flex align-items-center">
                  {/* <div className="mutated-text-14">East York</div>  */}
                  {/* <div className="mutated-text-14 px-2">•</div>  */}
                  <div className="mutated-text-14">
                    {Math.floor(eventDetails.events.distance)}km away
                  </div>
                </div>
              </div>
              <div className="border-div"></div>
              <div>
                <h5 className="font-blue-16">Learn more</h5>
                {eventDetails && (
                  <>
                    {/* {eventDetails.events.facility && (
                      <div className="d-flex mt-3">
                        <img src="/images/marker-pin-06.svg" alt="facility" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          {eventDetails.events.facility}
                        </div>
                      </div>
                    )} */}
                    {eventDetails.events.organized_by && (
                      <div className="d-flex mt-2">
                        <img src="/images/home.svg" alt="home" />
                        <div className="mutated-text-14-bold ms-2">
                          {eventDetails.events.organized_by}
                        </div>
                      </div>
                    )}
                    {eventDetails.events.age && (
                      <div className="d-flex mt-2">
                        <img src="/images/calendar-date.svg" alt="age" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          {eventDetails.events.age}
                        </div>
                      </div>
                    )}
                    {eventDetails.events.level && (
                      <div className="d-flex mt-2">
                        <img src="/images/award-03.svg" alt="level" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          {eventDetails.events.level == 100
                            ? "All Levels"
                            : eventDetails.events.level}
                        </div>
                      </div>
                    )}
                    {eventDetails.events.type && (
                      <div className="d-flex mt-2">
                        <img src="/images/check-circle.svg" alt="type" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          {eventDetails.events.type}
                        </div>
                      </div>
                    )}
                    {eventDetails.events.frequency && (
                      <div className="d-flex mt-2">
                        <img src="/images/refresh-ccw-02.svg" alt="frequency" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          {eventDetails.events.frequency
                            .charAt(0)
                            .toUpperCase() +
                            eventDetails.events.frequency.slice(1)}
                        </div>
                      </div>
                    )}
                    {/* {eventDetails.events.cost  && ( */}
                    <div className="d-flex mt-2">
                      <img
                        src="/images/currency-dollar-circle.svg"
                        alt="cost"
                      />
                      <div className="mutated-text-14-bold ms-2 pt-1">
                        {eventDetails.events.cost}
                      </div>
                    </div>
                    {/* )} */}
                    {eventDetails.events.membership && (
                      <div className="d-flex mt-2">
                        <img src="/images/star-01.svg" alt="membership" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          {eventDetails.events.membership}
                        </div>
                      </div>
                    )}
                    {eventDetails.events.email && (
                      <div className="d-flex mt-2">
                        <img src="/images/at-sign.svg" alt="email" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          <a href={`mailto:${eventDetails.events.email}`}>
                            {eventDetails.events.email}
                          </a>
                        </div>
                      </div>
                    )}
                    {eventDetails.events.phone && (
                      <div className="d-flex mt-2">
                        <img src="/images/phone.svg" alt="phone" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          <a href={`tel:${eventDetails.events.phone}`}>
                            {eventDetails.events.phone}
                          </a>
                        </div>
                      </div>
                    )}
                    {eventDetails.events.website && (
                      <div className="d-flex mt-2">
                        <img src="/images/link-external-01.svg" alt="website" />
                        <div className="mutated-text-14-bold ms-2 pt-1">
                          <a
                            href={eventDetails.events.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {eventDetails.events.website}
                          </a>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="border-div"></div>
              {/* <MoreEvents currentevent={eventDetails?.events?.id} name={eventDetails?.events?.facility} /> */}
            </div>

            {/* <div className="border-div"></div> */}
          </div>
          <div className="row">
            <div className="col-12 ">
              {eventDetails?.events?.facility_info && (
                <MoreEvents
                  currentevent={eventDetails?.events?.id}
                  name={eventDetails?.events?.facility}
                  image={
                    eventDetails?.events?.facility_info?.image?.[0]?.img ??
                    "/images/Rectangle-9.jpg"
                  }
                />
              )}
            </div>
          </div>
        </section>
        {/* <section className="facility">
          <div>
            <h5 className="font-blue-20">Comments</h5>

            <div className="add-comment-main event-detail-row p-3">
              <textarea
                className="comment-textarea"
                name=""
                id=""
                rows={3}
                placeholder="Add comment"
              ></textarea>
              <div className="text-end">
                <img src="/images/photograph.svg" />
                <button className="join-btn">Submit</button>
              </div>
            </div>
            <div className="comment-list mt-4">
              <div className="comment-info mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <img className="comment-image" src="/images/Avatar.png" />
                    <div className="comment-name ms-3">Micheal Gough</div>
                    <div className="comment-date ms-3">Nov 18/22 2:20PM</div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M5.25657 10.5886C5.41285 10.4323 5.50065 10.2204 5.50065 9.99935C5.50065 9.77833 5.41285 9.56637 5.25657 9.41009C5.10029 9.25381 4.88833 9.16602 4.66732 9.16602C4.4463 9.16602 4.23434 9.25381 4.07806 9.41009C3.92178 9.56637 3.83398 9.77833 3.83398 9.99935C3.83398 10.2204 3.92178 10.4323 4.07806 10.5886C4.23434 10.7449 4.4463 10.8327 4.66732 10.8327C4.88833 10.8327 5.10029 10.7449 5.25657 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M11.0899 10.5886C11.2462 10.4323 11.334 10.2204 11.334 9.99935C11.334 9.77833 11.2462 9.56637 11.0899 9.41009C10.9336 9.25381 10.7217 9.16602 10.5007 9.16602C10.2796 9.16602 10.0677 9.25381 9.9114 9.41009C9.75512 9.56637 9.66732 9.77833 9.66732 9.99935C9.66732 10.2204 9.75512 10.4323 9.9114 10.5886C10.0677 10.7449 10.2796 10.8327 10.5007 10.8327C10.7217 10.8327 10.9336 10.7449 11.0899 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M16.9232 10.5886C17.0795 10.4323 17.1673 10.2204 17.1673 9.99935C17.1673 9.77833 17.0795 9.56637 16.9232 9.41009C16.767 9.25381 16.555 9.16602 16.334 9.16602C16.113 9.16602 15.901 9.25381 15.7447 9.41009C15.5884 9.56637 15.5007 9.77833 15.5007 9.99935C15.5007 10.2204 15.5884 10.4323 15.7447 10.5886C15.901 10.7449 16.113 10.8327 16.334 10.8327C16.555 10.8327 16.767 10.7449 16.9232 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M4.66732 9.99935H4.67565M10.5007 9.99935H10.509M16.334 9.99935H16.3423M5.50065 9.99935C5.50065 10.2204 5.41285 10.4323 5.25657 10.5886C5.10029 10.7449 4.88833 10.8327 4.66732 10.8327C4.4463 10.8327 4.23434 10.7449 4.07806 10.5886C3.92178 10.4323 3.83398 10.2204 3.83398 9.99935C3.83398 9.77833 3.92178 9.56637 4.07806 9.41009C4.23434 9.25381 4.4463 9.16602 4.66732 9.16602C4.88833 9.16602 5.10029 9.25381 5.25657 9.41009C5.41285 9.56637 5.50065 9.77833 5.50065 9.99935ZM11.334 9.99935C11.334 10.2204 11.2462 10.4323 11.0899 10.5886C10.9336 10.7449 10.7217 10.8327 10.5007 10.8327C10.2796 10.8327 10.0677 10.7449 9.9114 10.5886C9.75512 10.4323 9.66732 10.2204 9.66732 9.99935C9.66732 9.77833 9.75512 9.56637 9.9114 9.41009C10.0677 9.25381 10.2796 9.16602 10.5007 9.16602C10.7217 9.16602 10.9336 9.25381 11.0899 9.41009C11.2462 9.56637 11.334 9.77833 11.334 9.99935ZM17.1673 9.99935C17.1673 10.2204 17.0795 10.4323 16.9232 10.5886C16.767 10.7449 16.555 10.8327 16.334 10.8327C16.113 10.8327 15.901 10.7449 15.7447 10.5886C15.5884 10.4323 15.5007 10.2204 15.5007 9.99935C15.5007 9.77833 15.5884 9.56637 15.7447 9.41009C15.901 9.25381 16.113 9.16602 16.334 9.16602C16.555 9.16602 16.767 9.25381 16.9232 9.41009C17.0795 9.56637 17.1673 9.77833 17.1673 9.99935Z"
                        stroke="#9CA3AF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="comment-desc mt-2">
                  Very straight-to-point article. Really worth time reading. Thank
                  you! But tools are just the instruments for the UX designers.
                  The knowledge of the design tools are as important as the
                  creation of the design strategy.
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.35481 5.15568C4.02991 4.48078 4.94542 4.10165 5.90001 4.10165C6.8546 4.10165 7.77011 4.48078 8.44521 5.15568L9.50001 6.20958L10.5548 5.15568C10.8869 4.81184 11.2841 4.53759 11.7234 4.34891C12.1626 4.16024 12.635 4.06093 13.113 4.05678C13.591 4.05262 14.065 4.14371 14.5074 4.32472C14.9499 4.50573 15.3518 4.77304 15.6898 5.11106C16.0278 5.44907 16.2952 5.85102 16.4762 6.29344C16.6572 6.73587 16.7483 7.20992 16.7441 7.68792C16.74 8.16593 16.6406 8.63832 16.452 9.07753C16.2633 9.51675 15.989 9.91399 15.6452 10.2461L9.50001 16.3922L3.35481 10.2461C2.67992 9.57098 2.30078 8.65547 2.30078 7.70088C2.30078 6.74629 2.67992 5.83078 3.35481 5.15568Z"
                        fill="#6B7280"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">11 Likes</div>
                  <div className="ms-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M6.5 9.5H6.5075M9.5 9.5H9.5075M12.5 9.5H12.5075M16.25 9.5C16.25 12.8135 13.2275 15.5 9.5 15.5C8.39648 15.5038 7.30609 15.2606 6.30875 14.7883L2.75 15.5L3.79625 12.71C3.134 11.7815 2.75 10.6805 2.75 9.5C2.75 6.1865 5.7725 3.5 9.5 3.5C13.2275 3.5 16.25 6.1865 16.25 9.5Z"
                        stroke="#6B7280"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">Reply</div>
                </div>
              </div>
              <div className="comment-info mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <img className="comment-image" src="/images/Avatar.png" />
                    <div className="comment-name ms-3">Jese Leos</div>
                    <div className="comment-date ms-3">Nov 18/22 2:20PM</div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M5.25657 10.5886C5.41285 10.4323 5.50065 10.2204 5.50065 9.99935C5.50065 9.77833 5.41285 9.56637 5.25657 9.41009C5.10029 9.25381 4.88833 9.16602 4.66732 9.16602C4.4463 9.16602 4.23434 9.25381 4.07806 9.41009C3.92178 9.56637 3.83398 9.77833 3.83398 9.99935C3.83398 10.2204 3.92178 10.4323 4.07806 10.5886C4.23434 10.7449 4.4463 10.8327 4.66732 10.8327C4.88833 10.8327 5.10029 10.7449 5.25657 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M11.0899 10.5886C11.2462 10.4323 11.334 10.2204 11.334 9.99935C11.334 9.77833 11.2462 9.56637 11.0899 9.41009C10.9336 9.25381 10.7217 9.16602 10.5007 9.16602C10.2796 9.16602 10.0677 9.25381 9.9114 9.41009C9.75512 9.56637 9.66732 9.77833 9.66732 9.99935C9.66732 10.2204 9.75512 10.4323 9.9114 10.5886C10.0677 10.7449 10.2796 10.8327 10.5007 10.8327C10.7217 10.8327 10.9336 10.7449 11.0899 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M16.9232 10.5886C17.0795 10.4323 17.1673 10.2204 17.1673 9.99935C17.1673 9.77833 17.0795 9.56637 16.9232 9.41009C16.767 9.25381 16.555 9.16602 16.334 9.16602C16.113 9.16602 15.901 9.25381 15.7447 9.41009C15.5884 9.56637 15.5007 9.77833 15.5007 9.99935C15.5007 10.2204 15.5884 10.4323 15.7447 10.5886C15.901 10.7449 16.113 10.8327 16.334 10.8327C16.555 10.8327 16.767 10.7449 16.9232 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M4.66732 9.99935H4.67565M10.5007 9.99935H10.509M16.334 9.99935H16.3423M5.50065 9.99935C5.50065 10.2204 5.41285 10.4323 5.25657 10.5886C5.10029 10.7449 4.88833 10.8327 4.66732 10.8327C4.4463 10.8327 4.23434 10.7449 4.07806 10.5886C3.92178 10.4323 3.83398 10.2204 3.83398 9.99935C3.83398 9.77833 3.92178 9.56637 4.07806 9.41009C4.23434 9.25381 4.4463 9.16602 4.66732 9.16602C4.88833 9.16602 5.10029 9.25381 5.25657 9.41009C5.41285 9.56637 5.50065 9.77833 5.50065 9.99935ZM11.334 9.99935C11.334 10.2204 11.2462 10.4323 11.0899 10.5886C10.9336 10.7449 10.7217 10.8327 10.5007 10.8327C10.2796 10.8327 10.0677 10.7449 9.9114 10.5886C9.75512 10.4323 9.66732 10.2204 9.66732 9.99935C9.66732 9.77833 9.75512 9.56637 9.9114 9.41009C10.0677 9.25381 10.2796 9.16602 10.5007 9.16602C10.7217 9.16602 10.9336 9.25381 11.0899 9.41009C11.2462 9.56637 11.334 9.77833 11.334 9.99935ZM17.1673 9.99935C17.1673 10.2204 17.0795 10.4323 16.9232 10.5886C16.767 10.7449 16.555 10.8327 16.334 10.8327C16.113 10.8327 15.901 10.7449 15.7447 10.5886C15.5884 10.4323 15.5007 10.2204 15.5007 9.99935C15.5007 9.77833 15.5884 9.56637 15.7447 9.41009C15.901 9.25381 16.113 9.16602 16.334 9.16602C16.555 9.16602 16.767 9.25381 16.9232 9.41009C17.0795 9.56637 17.1673 9.77833 17.1673 9.99935Z"
                        stroke="#9CA3AF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="comment-desc mt-2">
                  Thank you, Glad you liked it :D
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.35481 5.15568C4.02991 4.48078 4.94542 4.10165 5.90001 4.10165C6.8546 4.10165 7.77011 4.48078 8.44521 5.15568L9.50001 6.20958L10.5548 5.15568C10.8869 4.81184 11.2841 4.53759 11.7234 4.34891C12.1626 4.16024 12.635 4.06093 13.113 4.05678C13.591 4.05262 14.065 4.14371 14.5074 4.32472C14.9499 4.50573 15.3518 4.77304 15.6898 5.11106C16.0278 5.44907 16.2952 5.85102 16.4762 6.29344C16.6572 6.73587 16.7483 7.20992 16.7441 7.68792C16.74 8.16593 16.6406 8.63832 16.452 9.07753C16.2633 9.51675 15.989 9.91399 15.6452 10.2461L9.50001 16.3922L3.35481 10.2461C2.67992 9.57098 2.30078 8.65547 2.30078 7.70088C2.30078 6.74629 2.67992 5.83078 3.35481 5.15568Z"
                        fill="#6B7280"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">4 Likes</div>
                  <div className="ms-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M6.5 9.5H6.5075M9.5 9.5H9.5075M12.5 9.5H12.5075M16.25 9.5C16.25 12.8135 13.2275 15.5 9.5 15.5C8.39648 15.5038 7.30609 15.2606 6.30875 14.7883L2.75 15.5L3.79625 12.71C3.134 11.7815 2.75 10.6805 2.75 9.5C2.75 6.1865 5.7725 3.5 9.5 3.5C13.2275 3.5 16.25 6.1865 16.25 9.5Z"
                        stroke="#6B7280"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">Reply</div>
                </div>
              </div>
              <div className="comment-info mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <img className="comment-image" src="/images/Avatar.png" />
                    <div className="comment-name ms-3">Bonnie Green</div>
                    <div className="comment-date ms-3">Nov 18/22 2:20PM</div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M5.25657 10.5886C5.41285 10.4323 5.50065 10.2204 5.50065 9.99935C5.50065 9.77833 5.41285 9.56637 5.25657 9.41009C5.10029 9.25381 4.88833 9.16602 4.66732 9.16602C4.4463 9.16602 4.23434 9.25381 4.07806 9.41009C3.92178 9.56637 3.83398 9.77833 3.83398 9.99935C3.83398 10.2204 3.92178 10.4323 4.07806 10.5886C4.23434 10.7449 4.4463 10.8327 4.66732 10.8327C4.88833 10.8327 5.10029 10.7449 5.25657 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M11.0899 10.5886C11.2462 10.4323 11.334 10.2204 11.334 9.99935C11.334 9.77833 11.2462 9.56637 11.0899 9.41009C10.9336 9.25381 10.7217 9.16602 10.5007 9.16602C10.2796 9.16602 10.0677 9.25381 9.9114 9.41009C9.75512 9.56637 9.66732 9.77833 9.66732 9.99935C9.66732 10.2204 9.75512 10.4323 9.9114 10.5886C10.0677 10.7449 10.2796 10.8327 10.5007 10.8327C10.7217 10.8327 10.9336 10.7449 11.0899 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M16.9232 10.5886C17.0795 10.4323 17.1673 10.2204 17.1673 9.99935C17.1673 9.77833 17.0795 9.56637 16.9232 9.41009C16.767 9.25381 16.555 9.16602 16.334 9.16602C16.113 9.16602 15.901 9.25381 15.7447 9.41009C15.5884 9.56637 15.5007 9.77833 15.5007 9.99935C15.5007 10.2204 15.5884 10.4323 15.7447 10.5886C15.901 10.7449 16.113 10.8327 16.334 10.8327C16.555 10.8327 16.767 10.7449 16.9232 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M4.66732 9.99935H4.67565M10.5007 9.99935H10.509M16.334 9.99935H16.3423M5.50065 9.99935C5.50065 10.2204 5.41285 10.4323 5.25657 10.5886C5.10029 10.7449 4.88833 10.8327 4.66732 10.8327C4.4463 10.8327 4.23434 10.7449 4.07806 10.5886C3.92178 10.4323 3.83398 10.2204 3.83398 9.99935C3.83398 9.77833 3.92178 9.56637 4.07806 9.41009C4.23434 9.25381 4.4463 9.16602 4.66732 9.16602C4.88833 9.16602 5.10029 9.25381 5.25657 9.41009C5.41285 9.56637 5.50065 9.77833 5.50065 9.99935ZM11.334 9.99935C11.334 10.2204 11.2462 10.4323 11.0899 10.5886C10.9336 10.7449 10.7217 10.8327 10.5007 10.8327C10.2796 10.8327 10.0677 10.7449 9.9114 10.5886C9.75512 10.4323 9.66732 10.2204 9.66732 9.99935C9.66732 9.77833 9.75512 9.56637 9.9114 9.41009C10.0677 9.25381 10.2796 9.16602 10.5007 9.16602C10.7217 9.16602 10.9336 9.25381 11.0899 9.41009C11.2462 9.56637 11.334 9.77833 11.334 9.99935ZM17.1673 9.99935C17.1673 10.2204 17.0795 10.4323 16.9232 10.5886C16.767 10.7449 16.555 10.8327 16.334 10.8327C16.113 10.8327 15.901 10.7449 15.7447 10.5886C15.5884 10.4323 15.5007 10.2204 15.5007 9.99935C15.5007 9.77833 15.5884 9.56637 15.7447 9.41009C15.901 9.25381 16.113 9.16602 16.334 9.16602C16.555 9.16602 16.767 9.25381 16.9232 9.41009C17.0795 9.56637 17.1673 9.77833 17.1673 9.99935Z"
                        stroke="#9CA3AF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="comment-desc mt-2">
                  The article covers the essentials, challenges, myths and stages the UX designer should consider while creating the design strategy.
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.35481 5.15568C4.02991 4.48078 4.94542 4.10165 5.90001 4.10165C6.8546 4.10165 7.77011 4.48078 8.44521 5.15568L9.50001 6.20958L10.5548 5.15568C10.8869 4.81184 11.2841 4.53759 11.7234 4.34891C12.1626 4.16024 12.635 4.06093 13.113 4.05678C13.591 4.05262 14.065 4.14371 14.5074 4.32472C14.9499 4.50573 15.3518 4.77304 15.6898 5.11106C16.0278 5.44907 16.2952 5.85102 16.4762 6.29344C16.6572 6.73587 16.7483 7.20992 16.7441 7.68792C16.74 8.16593 16.6406 8.63832 16.452 9.07753C16.2633 9.51675 15.989 9.91399 15.6452 10.2461L9.50001 16.3922L3.35481 10.2461C2.67992 9.57098 2.30078 8.65547 2.30078 7.70088C2.30078 6.74629 2.67992 5.83078 3.35481 5.15568Z"
                        fill="#6B7280"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">24 Likes</div>
                  <div className="ms-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M6.5 9.5H6.5075M9.5 9.5H9.5075M12.5 9.5H12.5075M16.25 9.5C16.25 12.8135 13.2275 15.5 9.5 15.5C8.39648 15.5038 7.30609 15.2606 6.30875 14.7883L2.75 15.5L3.79625 12.71C3.134 11.7815 2.75 10.6805 2.75 9.5C2.75 6.1865 5.7725 3.5 9.5 3.5C13.2275 3.5 16.25 6.1865 16.25 9.5Z"
                        stroke="#6B7280"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">Reply</div>
                </div>
              </div>
              <div className="comment-info mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <img className="comment-image" src="/images/Avatar.png" />
                    <div className="comment-name ms-3">Helene Engels</div>
                    <div className="comment-date ms-3">Nov 18/22 2:20PM</div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M5.25657 10.5886C5.41285 10.4323 5.50065 10.2204 5.50065 9.99935C5.50065 9.77833 5.41285 9.56637 5.25657 9.41009C5.10029 9.25381 4.88833 9.16602 4.66732 9.16602C4.4463 9.16602 4.23434 9.25381 4.07806 9.41009C3.92178 9.56637 3.83398 9.77833 3.83398 9.99935C3.83398 10.2204 3.92178 10.4323 4.07806 10.5886C4.23434 10.7449 4.4463 10.8327 4.66732 10.8327C4.88833 10.8327 5.10029 10.7449 5.25657 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M11.0899 10.5886C11.2462 10.4323 11.334 10.2204 11.334 9.99935C11.334 9.77833 11.2462 9.56637 11.0899 9.41009C10.9336 9.25381 10.7217 9.16602 10.5007 9.16602C10.2796 9.16602 10.0677 9.25381 9.9114 9.41009C9.75512 9.56637 9.66732 9.77833 9.66732 9.99935C9.66732 10.2204 9.75512 10.4323 9.9114 10.5886C10.0677 10.7449 10.2796 10.8327 10.5007 10.8327C10.7217 10.8327 10.9336 10.7449 11.0899 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M16.9232 10.5886C17.0795 10.4323 17.1673 10.2204 17.1673 9.99935C17.1673 9.77833 17.0795 9.56637 16.9232 9.41009C16.767 9.25381 16.555 9.16602 16.334 9.16602C16.113 9.16602 15.901 9.25381 15.7447 9.41009C15.5884 9.56637 15.5007 9.77833 15.5007 9.99935C15.5007 10.2204 15.5884 10.4323 15.7447 10.5886C15.901 10.7449 16.113 10.8327 16.334 10.8327C16.555 10.8327 16.767 10.7449 16.9232 10.5886Z"
                        fill="#6B7280"
                      />
                      <path
                        d="M4.66732 9.99935H4.67565M10.5007 9.99935H10.509M16.334 9.99935H16.3423M5.50065 9.99935C5.50065 10.2204 5.41285 10.4323 5.25657 10.5886C5.10029 10.7449 4.88833 10.8327 4.66732 10.8327C4.4463 10.8327 4.23434 10.7449 4.07806 10.5886C3.92178 10.4323 3.83398 10.2204 3.83398 9.99935C3.83398 9.77833 3.92178 9.56637 4.07806 9.41009C4.23434 9.25381 4.4463 9.16602 4.66732 9.16602C4.88833 9.16602 5.10029 9.25381 5.25657 9.41009C5.41285 9.56637 5.50065 9.77833 5.50065 9.99935ZM11.334 9.99935C11.334 10.2204 11.2462 10.4323 11.0899 10.5886C10.9336 10.7449 10.7217 10.8327 10.5007 10.8327C10.2796 10.8327 10.0677 10.7449 9.9114 10.5886C9.75512 10.4323 9.66732 10.2204 9.66732 9.99935C9.66732 9.77833 9.75512 9.56637 9.9114 9.41009C10.0677 9.25381 10.2796 9.16602 10.5007 9.16602C10.7217 9.16602 10.9336 9.25381 11.0899 9.41009C11.2462 9.56637 11.334 9.77833 11.334 9.99935ZM17.1673 9.99935C17.1673 10.2204 17.0795 10.4323 16.9232 10.5886C16.767 10.7449 16.555 10.8327 16.334 10.8327C16.113 10.8327 15.901 10.7449 15.7447 10.5886C15.5884 10.4323 15.5007 10.2204 15.5007 9.99935C15.5007 9.77833 15.5884 9.56637 15.7447 9.41009C15.901 9.25381 16.113 9.16602 16.334 9.16602C16.555 9.16602 16.767 9.25381 16.9232 9.41009C17.0795 9.56637 17.1673 9.77833 17.1673 9.99935Z"
                        stroke="#9CA3AF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="comment-desc mt-2">
                  Thanks for sharing this. I do came from the Backend development and explored some of the tools to design my Side Projects.
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.35481 5.15568C4.02991 4.48078 4.94542 4.10165 5.90001 4.10165C6.8546 4.10165 7.77011 4.48078 8.44521 5.15568L9.50001 6.20958L10.5548 5.15568C10.8869 4.81184 11.2841 4.53759 11.7234 4.34891C12.1626 4.16024 12.635 4.06093 13.113 4.05678C13.591 4.05262 14.065 4.14371 14.5074 4.32472C14.9499 4.50573 15.3518 4.77304 15.6898 5.11106C16.0278 5.44907 16.2952 5.85102 16.4762 6.29344C16.6572 6.73587 16.7483 7.20992 16.7441 7.68792C16.74 8.16593 16.6406 8.63832 16.452 9.07753C16.2633 9.51675 15.989 9.91399 15.6452 10.2461L9.50001 16.3922L3.35481 10.2461C2.67992 9.57098 2.30078 8.65547 2.30078 7.70088C2.30078 6.74629 2.67992 5.83078 3.35481 5.15568Z"
                        fill="#6B7280"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">9 Likes</div>
                  <div className="ms-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M6.5 9.5H6.5075M9.5 9.5H9.5075M12.5 9.5H12.5075M16.25 9.5C16.25 12.8135 13.2275 15.5 9.5 15.5C8.39648 15.5038 7.30609 15.2606 6.30875 14.7883L2.75 15.5L3.79625 12.71C3.134 11.7815 2.75 10.6805 2.75 9.5C2.75 6.1865 5.7725 3.5 9.5 3.5C13.2275 3.5 16.25 6.1865 16.25 9.5Z"
                        stroke="#6B7280"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mutated-text-14 ms-2">Reply</div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <Footer />
    </>
  );
};

export default EventDetails;
