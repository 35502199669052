import React, { createContext, useState, useContext } from 'react';

// Define a type for the context state to allow null
type LocationState = {
  lat: number;
  lng: number;
  address: {
    label: string;
  };
} | null;

// Define a type for the context value that includes the state and the updater function
type LocationContextType = {
  selectedLocationSaved: LocationState;
  setSelectedLocationSaved: React.Dispatch<React.SetStateAction<LocationState>>;
};

// Create a Context with the defined type, providing a default value
const LocationContext = createContext<LocationContextType>({} as LocationContextType);

// Create a Provider Component with typed children
export const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  // Start with a null state for selected location
  const [selectedLocationSaved, setSelectedLocationSaved] = useState<LocationState>(null);

  return (
    <LocationContext.Provider value={{ selectedLocationSaved, setSelectedLocationSaved }}>
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook to use the location context
export const useLocation = () => useContext(LocationContext);
