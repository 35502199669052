// Header.tsx
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  showDownload?: boolean;
};

const Header: React.FC<Props> = ({ showDownload = true }) => {
  const navigate = useNavigate();

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <header>
      {
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid main-cointaner">
            {/*Left side: Logo and Page Links*/}
            <a className="navbar-brand" href="/" onClick={handleLogoClick}>
              <img
                width="130px"
                src="/images/FooterLogo.svg"
                alt="Logo"
                className="d-inline-block align-top"
              />
            </a>

            {/* Navbar toggle button for smaller screens*/}
            <button
              className="navbar-toggler"
              hidden
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Responsive Navigation Links*/}
            <div className="navbar-collapse" id="navbarNav">
              <div className="navbar-nav">
                {showDownload && (
                  <a className="nav-link" href="/downloadapp">
                    Download Mobile App
                  </a>
                )}
              </div>
            </div>
          </div>
        </nav>
      }
    </header>
  );
};

export default Header;
