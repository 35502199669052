// utils/googleMapsLoader.ts
let geocoderInstance: google.maps.Geocoder | null = null;

export const loadGoogleMapsAPI = (apiKey: string): Promise<google.maps.Geocoder> => {
  return new Promise((resolve, reject) => {
    if (geocoderInstance) {
      resolve(geocoderInstance);
      return;
    }

    if (typeof window.google === "object" && typeof window.google.maps === "object") {
      geocoderInstance = new window.google.maps.Geocoder();
      resolve(geocoderInstance);
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      geocoderInstance = new window.google.maps.Geocoder();
      resolve(geocoderInstance as google.maps.Geocoder);
    };
    script.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(script);
  });
};