import React from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";

const DownloadApp: React.FC = () => {
  return (
    <>
      <Header showDownload={false} />
      <div className="app-container">
        <div className="container">
          <section>
            <div
              className="row custom-py-69 app-details align-items-center"
              id="showAllSection"
            >
              <div className="col-12 col-md-6 mb-3">
                <h3 className="midium-text">Let’s play pickleball</h3>
                <p className="mutated-text-20">
                  Organize and find local pickleball games. Connect with fellow
                  pickleball enthusiasts, enhance your playing skills, or enjoy
                  a friendly Pickleball match. Set up, join, and play pickleball
                  in your area or any searched location. Discover, connect, and
                  play with Pickleball Match.
                </p>
                <a href="https://apps.apple.com/us/app/pickleball-match/id6451394447">
                  <img
                    src="/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                    width="200"
                  />
                </a>
              </div>
              <div className="col-12 col-md-6">
                <div className="">
                  <img width="100%" src="/images/app-image.png" />
                  <img />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadApp;
