import axios, { AxiosInstance } from "axios";
import { endpoints } from "../../../api/Endpoints/index";
import { GET_EVENT_BY_ID } from "../../actionTypes";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const GetEventByID = (
  eventId: string,
  success = () => {},
  error = () => {}
) => {
  return async (dispatch: (action: { type: string; payload?: any }) => void) => {
    try {
      const storedLocation = localStorage.getItem('position');
      // console.log("sdsd",storedLocation);
      const response = await axiosInstance.post(
        `/ios/${endpoints.event}/${endpoints.singleEvent}`,
        {
            id: Number(eventId),
            geocode: storedLocation,
          }
      );

      const eventData = response?.data?.data;

      if (eventData) {
        

        dispatch({
          type: GET_EVENT_BY_ID,
          payload: eventData,
        });

        success();
      } else {
        console.error("Data is undefined or null");
        error();
      }
    } catch (err) {
      console.error("Unexpected error:", err);

    //   // Check if the error response is available
    //   if (err.response) {
    //     console.error("Error response from server:", err.response.data);
    //   }

      error();
    }
  };
};
