// src/store/reducers/eventManagementReducer.ts

import { GET_EVENT_BY_ID } from "../../actionTypes";

const initialState = {
  events: [],
};

const eventManagementReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case GET_EVENT_BY_ID:
      return {
        ...state,
        events: action.payload,
      };
    default:
      return state;
  }
};

export default eventManagementReducer;
