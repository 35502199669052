// MoreEvents.tsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetEventsByFacility } from "../store/actions/facilities";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RootState } from "store/reducers";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
interface MoreEventsProps {
  name: string; // Adjust the type as needed
  currentevent: string; 
  image:string// Adjust the type as needed
}
// const formatDate = (dateString: string) => {
//   const [year, month, day] = dateString.split("-");
//   const options: Intl.DateTimeFormatOptions = {
//     weekday: "short",
//     month: "short",
//     day: "numeric",
//     year: "numeric",
//   };
//   const formattedDate = new Date(`${month} ${day}, ${year}`).toLocaleDateString(
//     "en-US",
//     options
//   );
//   return formattedDate;
// };
const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  // Split the ISO 8601 date string
  const [year, month, day] = dateString.split("-");

  // Construct the date using the ISO 8601 format
  const formattedDate = new Date(`${year}-${month}-${day}`).toLocaleDateString(
    "en-US",
    options
  );

  return formattedDate;
};

const formatTimeRange = (startTime: string, endTime: string) => {
  const formatTime = (time: string) => {
    const timePart =
      time.split("T")[1].split(":")[0] + ":" + time.split("T")[1].split(":")[1];
    const [hour, minute] = timePart.split(":");
    const period = parseInt(hour, 10) < 12 ? "am" : "pm";
    return `${parseInt(hour, 10) % 12 || 12}:${minute}${period}`;
  };

  return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};
const MoreEvents: React.FC<MoreEventsProps> = ({ currentevent, name,image }) => {
  const dispatch = useDispatch();
  const [mapKey, setMapKey] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [lat, setLat] = useState(0); // Set default values if needed
  const [lng, setLng] = useState(0); // Set default values if needed
  const [eventDetails, setEventDetails] = useState([]);
  
  const [facilityImage,setFacilityImage] = useState(image);
  
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const fetchEventsOfFacility = async () => {
      try {
        if (name) {
          // Fetch events
          setFacilityImage(image);
          const events = await dispatch(GetEventsByFacility(name));

          console.log("Fetched events:", events);

          // Update the local state with the fetched events
          if (events) {
            setEventDetails(events);
            setIsDataLoaded(true);
            // Increment the mapKey to trigger GoogleMapReact re-render
            setMapKey((prevKey) => prevKey + 1);
          } else {
            console.error("No events data received.");
          }
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEventsOfFacility();
  }, [dispatch, name]);
  return (
    <div>
      <div className="d-flex justify-content-between">
        {/* on click of this text, scroll to the top of the window */}
        <h5 className="font-blue-20" onClick={() => window.scrollTo(0, 0)}>
          {" "}
          Events happening here
        </h5>
        <div className="d-flex"></div>
      </div>

      <div className="event-card-info" style={{}}>
        <div className="event-detail-row mt-24 event-detail-slider" style={{}}>
          {Array.isArray(eventDetails) ? (
            <Slider {...settings}>
              {eventDetails.map(
                (event: {
                  members: number;
                  interested: any;
                  id: any;
                  isLimitChecked: boolean;
                }) =>
                  currentevent !== event.id ? (
                    <div className="col-width p-2" key={event.id}>
                      <NavLink
                        to={`/event-details/${event.id}`}
                        key={event.id}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card mb-2">
                          <div className="card-header">
                            <div className="more-events-div">
                              {event.image.img ? (
                                <div>
                                  <img
                                    className="catd-header-img"
                                    src={event.image.img}
                                    alt={event.name}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="catd-header-img"
                                    src={facilityImage}
                                    alt={event.name}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="card-body">
                            <h5 className="font-green-small">
                              {formatDate(event.start_date)}{" "}
                              <span className="px-1 fs-25">.</span>
                              {formatTimeRange(
                                event.start_time,
                                event.end_time
                              )}
                            </h5>
                            <h4
                              className="font-blue-16 overflow-content-title"
                              // style={{
                              //   whiteSpace: "normal",
                              //   wordWrap: "break-word",
                              // }}
                            >
                              {event.name}
                            </h4>
                            <p
                              className="mutated-text-14 mb-0 mb-0 overflow-content"
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {event.location}
                              {/* <span className="px-1 fs-25">.</span>  */}
                              {/* {Math.floor(event?.distance??0)} km away */}
                            </p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                >
                                  <path
                                    d="M13.9997 15L15.6663 16.6667L18.9997 13.3333M10.6663 12.5H7.33301C5.77987 12.5 5.0033 12.5 4.39073 12.7537C3.57397 13.092 2.92506 13.741 2.58674 14.5577C2.33301 15.1703 2.33301 15.9469 2.33301 17.5M13.583 2.7423C14.8046 3.23679 15.6663 4.43443 15.6663 5.83333C15.6663 7.23224 14.8046 8.42988 13.583 8.92437M11.9163 5.83333C11.9163 7.67428 10.424 9.16667 8.58301 9.16667C6.74206 9.16667 5.24967 7.67428 5.24967 5.83333C5.24967 3.99238 6.74206 2.5 8.58301 2.5C10.424 2.5 11.9163 3.99238 11.9163 5.83333Z"
                                    stroke="#5E5E5E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                                <div className="ms-2 mutated-text-14">
                                  {event?.interested?.length ?? 0}
                                  {event?.isLimitChecked ? "/" : ""}
                                  {event?.isLimitChecked
                                    ? event?.members ?? 0
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  ) : null
              )}
            </Slider>
          ) : (
            <p>Error loading events. Please try again.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoreEvents;
