// Import necessary dependencies
import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import rootReducer from "./store/reducers"; // Adjust this path

// Import your components
import Home from "../src/pages/home";
import { GoogleMapsProvider } from "./contexts/GoogleMapsContext";
import DownloadApp from "./pages/downloadapp";
import EventDetails from "./pages/eventdetails";
import FacilityDetails from "./pages/facilitydetails";
import PrivacyPolicy from "./pages/privacypolicy";

// Create your Redux store
const store = configureStore({
  reducer: rootReducer,
});

// Your main component
const App = () => {
  return (
    <GoogleMapsProvider>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/downloadapp" element={<DownloadApp />} />
            <Route path="/event-details/:eventId" element={<EventDetails />} />
            <Route
              path="/facility-details/:eventId"
              element={<FacilityDetails />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            {/* <Route path="/search-results/:locationParam" element={<Home />} /> */}
            <Route path="/search-results/" element={<Home />} />
          </Routes>
        </Router>
      </Provider>
    </GoogleMapsProvider>
  );
};

export default App;
