// src/store/reducers/index.ts

import { combineReducers } from 'redux';
import eventManagement from './eventManagement';
import facilityManagement from './facilityManagement';
// ... other reducers

// Define your RootState type
export type RootState = ReturnType<typeof rootReducer>;

// Combine all your reducers
const rootReducer = combineReducers({
  eventManagement: eventManagement,
  facilityManagement: facilityManagement,
  // ... other reducers
});

export default rootReducer;
