// src/layout/BodyContrainer.tsx;
import React, { useContext, useEffect, useState } from "react";
import { eventsList } from "../store/actions/home";
// import { Link as RouterLink } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AdSenseAd from "../components/Ad";
import ShimmerEvent from "../components/ShimmerEvent";
import { GoogleMapsContext } from "../contexts/GoogleMapsContext";

interface Facility {
  facility_id: number; // Add the correct type for facility_id
  events: any[]; // Assuming events is an array of any type
  // Add other properties if necessary
  [key: string]: any;
}

interface Event {
  interested: any;
  id: number;
  name: string;
  organized_by: string;
  facility: string;
  location: string;
  level: string;
  age: string;
  date: string;
  start_date: string;
  start_time: string; // Assuming the time is in the "2000-1-1T09:00" format
  end_time: string; // Assuming the time is in the "2000-1-1T21:00" format
  // Add other properties if necessary
}
interface BodyContainerProps {
  searchData: string;
  selectedLocation: any;
  UserPlace: any;
  setUserPlace: (location: any | null) => void;
}

interface SelectedPlace {
  lat: number;
  lng: number;
  address: {
    label: string;
  };
}

interface UserPlace {
  lat: number;
  lng: number;
  address: {
    label: string;
  };
}
// const ShimmerEvent = () => (
//     <div className="event-placeholder">
//       {/* Shimmer animation styling */}
//       Event is loading
//     </div>
//   );

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 1024, // tablet breakpoint
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 768, // mobile breakpoint
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };
const NextArrow = (props: { onClick: any }) => {
  const { onClick } = props;
  return (
    <div className="arrow slick-next" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M9.5 6L15.5 12L9.5 18"
          stroke="#5E5E5E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>
  );
};

// Previous Arrow component
const PrevArrow = (props: { onClick: any }) => {
  const { onClick } = props;
  return (
    <div className="arrow slick-prev" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M15.5 18L9.5 12L15.5 6"
          stroke="#5E5E5E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>
  );
};

const InterestedCount: React.FC<{
  interested?: any[]; // Make `interested` optional to handle undefined
  members?: number; // Make `members` optional
  isLimitChecked?: boolean; // Make `isLimitChecked` optional
}> = ({ interested = [], members = 0, isLimitChecked = false }) => {
  // Ensure interested is always an array to avoid undefined errors
  const interestedCount = Array.isArray(interested) ? interested.length : 0;

  return (
    interestedCount > 0 && (
      <div className="d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M13.9997 15L15.6663 16.6667L18.9997 13.3333M10.6663 12.5H7.33301C5.77987 12.5 5.0033 12.5 4.39073 12.7537C3.57397 13.092 2.92506 13.741 2.58674 14.5577C2.33301 15.1703 2.33301 15.9469 2.33301 17.5M13.583 2.7423C14.8046 3.23679 15.6663 4.43443 15.6663 5.83333C15.6663 7.23224 14.8046 8.42988 13.583 8.92437M11.9163 5.83333C11.9163 7.67428 10.424 9.16667 8.58301 9.16667C6.74206 9.16667 5.24967 7.67428 5.24967 5.83333C5.24967 3.99238 6.74206 2.5 8.58301 2.5C10.424 2.5 11.9163 3.99238 11.9163 5.83333Z"
            stroke="#5E5E5E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="ms-2 mutated-text-14">
          {interestedCount}
          {isLimitChecked ? `/${members}` : ""}
        </div>
      </div>
    )
  );
};

const settings = {
  dots: false,
  arrows: true,
  nextArrow: <NextArrow onClick={undefined} />,
  prevArrow: <PrevArrow onClick={undefined} />,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024, // tablet breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// const formatDate = (dateString: string) => {
//   const [year, month, day] = dateString.split("-");
//   const options: Intl.DateTimeFormatOptions = {
//     weekday: "short",
//     month: "short",
//     day: "numeric",
//     year: "numeric",
//   };
//   const formattedDate = new Date(`${month} ${day}, ${year}`).toLocaleDateString(
//     "en-US",
//     options
//   );
//   return formattedDate;
// };
const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  // Split the ISO 8601 date string
  const [year, month, day] = dateString.split("-");

  // Construct the date using the ISO 8601 format
  const formattedDate = new Date(`${year}-${month}-${day}`).toLocaleDateString(
    "en-US",
    options
  );

  return formattedDate;
};

const formatTimeRange = (startTime: string, endTime: string) => {
  const formatTime = (time: string) => {
    const timePart =
      time.split("T")[1].split(":")[0] + ":" + time.split("T")[1].split(":")[1];
    const [hour, minute] = timePart.split(":");

    const period = parseInt(hour, 10) < 12 ? "am" : "pm";
    return `${parseInt(hour, 10) % 12 || 12}:${minute}${period}`;
  };

  return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};

const BodyContainer: React.FC<BodyContainerProps> = ({
  searchData,
  selectedLocation,
  UserPlace,
  setUserPlace, // Add setUserPlace as a prop
}) => {
  // console.log("selectedsdsaLocation",selectedLocation);

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { geocoder } = useContext(GoogleMapsContext);
  // const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
  //   script.async = true;
  //   script.onload = () => {
  //     setGeocoder(new window.google.maps.Geocoder());
  //   };
  //   document.body.appendChild(script);
  // }, []);

  useEffect(() => {
    if (
      geocoder &&
      UserPlace &&
      UserPlace.lat &&
      UserPlace.lng &&
      UserPlace.address?.label !== "Toronto"
    ) {
      geocoder.geocode(
        { location: { lat: UserPlace.lat, lng: UserPlace.lng } },
        (results, status) => {
          if (status === "OK" && results && results[0]) {
            console.log(
              "results - address components",
              results[0].address_components
            );
            const { city, state } = extractCityAndState(
              results[0].address_components
            );
            const newUserPlace: UserPlace = {
              ...UserPlace,
              address: {
                label: `${city}, ${state}`, // Corrected string interpolation syntax
              },
            };
            // Assuming setUserPlace is a function to update the state elsewhere in your application
            console.log("Updated UserPlace:", newUserPlace);
            // Use setUserPlace to update the state
            setUserPlace(newUserPlace);
            // UserPlace.address.label = newUserPlace.address.label;
          } else {
            console.error("Reverse geocode failed due to:", status);
          }
        }
      );
    }
  }, [geocoder, UserPlace?.lat, UserPlace?.lng]);

  // Function to extract the city and state
  function extractCityAndState(components: any[]) {
    let city = "";
    let state = "";

    components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.short_name; // Use `long_name` if you prefer the full state name
      }
    });

    return { city, state };
  }

  const fetchDataFromApi = async (
    userPlace: UserPlace,
    selectedPlace: SelectedPlace,
    currentpage: number
  ) => {
    try {
      const { lat: latuser, lng: lnguser } = userPlace;

      const { lat = 0, lng = 0 } = selectedPlace || {};

      const result = await eventsList(
        `${latuser},${lnguser}`,
        `${lat},${lng}`,
        currentpage
      );

      if (result.success) {
        setCurrentpage(result.currentPage);
        setTotalPages(result.totalPage);
        setFacilities(result?.facility ?? []);
      } else {
        console.error("Error fetching events:", result.message);
      }
      setLoading(false);
    } catch (error) {
      setError("Error fetching data: " + error);
      setLoading(false);
    }
  };
  const goToPreviousPage = () => {
    setCurrentpage((prevPage: number) => Math.max(prevPage - 1, 1));

    fetchDataFromApi(UserPlace, selectedLocation, currentpage - 1);
  };

  // Function to handle going to the next page
  const goToNextPage = () => {
    // alert(currentpage+1);
    setCurrentpage((prevPage: number) => Math.min(prevPage + 1, totalPages));
    fetchDataFromApi(UserPlace, selectedLocation, currentpage + 1);
  };
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };
  const slickTracks = document.querySelectorAll(".slick-track");

  // if (slickTracks.length > 0) {
  //   slickTracks[0].classList.add('full-slider-width');
  // }

  useEffect(() => {
    // Assuming UserPlace is coming from somewhere else in your code

    fetchDataFromApi(UserPlace, selectedLocation, 1);
  }, [UserPlace, selectedLocation]);

  useEffect(() => {
    console.log("Selected Location:", selectedLocation);
  }, [selectedLocation]);

  return (
    <div className="app-container">
      <AdSenseAd key={"adsense1"} />
      <div className="container">
        {
          <>
            <section key={1}>
              <div className="custom-py-69">
                <div className="row justify-content-center">
                  <div className="col-12 d-sm-flex justify-content-between align-items-center">
                    <div>
                      <h3 className="midium-text">
                        Local pickleball games near{" "}
                        <span className="highlighted-location">
                          {selectedLocation?.address?.label ||
                            UserPlace?.address?.label ||
                            "Location not available"}
                        </span>
                      </h3>
                      <p className="mutated-text-20 mb-0">
                        {/* Open hours for recreational pickleball. */}
                      </p>
                    </div>
                    <div className="d-flex mb-2"></div>
                  </div>
                </div>

                {loading ? (
                  <ShimmerEvent />
                ) : facilities.length > 0 ? (
                  facilities.map(
                    (facility, index) =>
                      index !== -1 && (
                        <div
                          className={`eventDiv    index${index} currentpage-${currentpage} facility-${facility.facility_id}`}
                          style={{
                            display:
                              (currentpage === 1 && index === 0) ||
                              Math.ceil(index / currentpage) === 1
                                ? "block"
                                : "block",
                          }}
                        >
                          <div
                            className="event-card-info"
                            key={facility.facility_id}
                          >
                            <div
                              className={`event-detail-row mt-24 event-slider`}
                            >
                              {facility.events.length === 1 ? (
                                facility.events.map((event) => (
                                  <div
                                    className={` col-width p-2 h-full  ${event.id}`}
                                    style={{ width: "417px" }}
                                    key={event.id}
                                  >
                                    <NavLink
                                      to={`/event-details/${event.id}`}
                                      key={event.id}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="card mb-2">
                                        <div
                                          className="card-header"
                                          style={{ width: "100%" }}
                                        >
                                          <div style={{ width: "100%" }}>
                                            <div style={{ width: "100%" }}>
                                              {event?.image?.[0]?.img ? ( // Check if event image exists
                                                <div className="events-div">
                                                  <img
                                                    className="catd-header-img"
                                                    src={event?.image?.[0]?.img}
                                                    alt={event.name}
                                                  />
                                                </div> // If yes, display event image
                                              ) : // If not, check for facility images
                                              facility?.image?.[0]?.img ? ( // Check if facility images exist
                                                <div className="events-div">
                                                  <img
                                                    className="catd-header-img"
                                                    src={facility.image[0].img}
                                                    alt={event.name}
                                                  />
                                                </div> // If yes, display the first facility image
                                              ) : (
                                                // If not, display default placeholder
                                                <div className="events-div">
                                                  <img
                                                    className="catd-header-img"
                                                    src="/images/Rectangle-9home.png"
                                                    alt={event.name}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-body">
                                          <h5 className="font-green-small">
                                            <div className="d-flex flex-column justify-content-between">
                                              {formatDate(event.start_date)}{" "}
                                              <br />
                                              {formatTimeRange(
                                                event.start_time,
                                                event.end_time
                                              )}
                                            </div>
                                          </h5>

                                          <h4
                                            className="font-blue-20"
                                            style={{
                                              whiteSpace: "normal",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {event.name}
                                          </h4>
                                          <p
                                            className="mutated-text-14 mb-0"
                                            style={{
                                              whiteSpace: "normal",
                                              wordWrap: "break-word",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            {event.location}
                                            {/* <span className="px-1 fs-25"></span> */}
                                            <br />
                                            {Math.floor(event.distance)} km away
                                          </p>
                                          <div
                                            className="d-flex justify-content-between align-items-center"
                                            style={{ marginTop: "10px" }}
                                          >
                                            <InterestedCount
                                              interested={event.interested}
                                              members={event.members}
                                              isLimitChecked={
                                                event.isLimitChecked
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>
                                ))
                              ) : (
                                <Slider {...settings}>
                                  {facility.events.map((event) => (
                                    <div
                                      className={` col-width p-2 h-full  ${event.id}`}
                                      key={event.id}
                                    >
                                      <NavLink
                                        to={`/event-details/${event.id}`}
                                        key={event.id}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <div className="card mb-2">
                                          <div
                                            className="card-header"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ width: "100%" }}>
                                              {event?.image?.[0]?.img ? ( // Check if event image exists
                                                <div className="events-div">
                                                  <img
                                                    className="catd-header-img"
                                                    src={event?.image?.[0]?.img}
                                                    alt={event.name}
                                                  />
                                                </div> // If yes, display event image
                                              ) : // If not, check for facility images
                                              facility?.image?.[0]?.img ? ( // Check if facility images exist
                                                <div className="events-div">
                                                  <img
                                                    className="catd-header-img"
                                                    src={facility.image[0].img}
                                                    alt={event.name}
                                                  />
                                                </div> // If yes, display the first facility image
                                              ) : (
                                                // If not, display default placeholder
                                                <div className="events-div">
                                                  <img
                                                    className="catd-header-img"
                                                    src="/images/Rectangle-9home.png"
                                                    alt={event.name}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="card-body">
                                            <h5 className="font-green-small">
                                              {formatDate(event.start_date)}
                                              <br />
                                              {formatTimeRange(
                                                event.start_time,
                                                event.end_time
                                              )}
                                            </h5>
                                            {/* <h4 className="font-blue-20">{truncateText(event.name, 25)}</h4> */}
                                            <h4
                                              className="font-blue-20"
                                              style={{
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              {event.name}
                                            </h4>
                                            <p
                                              className="mutated-text-14 mb-0"
                                              style={{
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              {event.location}
                                              {/* <span className="px-1 fs-25"></span> */}
                                              <br />
                                              {Math.floor(event.distance)} km
                                              away
                                            </p>
                                            <div
                                              className="d-flex justify-content-between align-items-center"
                                              style={{ marginTop: "10px" }}
                                            >
                                              <InterestedCount
                                                interested={event.interested}
                                                members={event.members}
                                                isLimitChecked={
                                                  event.isLimitChecked
                                                }
                                              />
                                              {/* {event?.interested && event?.interested[0]?.act ? (
                                                                                    <button className="going-btn">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                                            <path d="M13.9997 4.5L6.66634 11.8333L3.33301 8.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        </svg>
                                                                                        Interested
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="going-btn" style={{ height: '41px', visibility: 'hidden' }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                                            <path d="M13.9997 4.5L6.66634 11.8333L3.33301 8.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        </svg>
                                                                                        Interested
                                                                                    </button>
                                                                                )} */}

                                              {/* {event?.interested && event?.interested[0]?.act && (
                                                                                <button className="going-btn">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                                        <path d="M13.9997 4.5L6.66634 11.8333L3.33301 8.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                    Interested
                                                                                </button>
                                                                            )} */}
                                            </div>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                  ))}
                                </Slider>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <p>No events available.</p>
                )}
                {/* <div className="text-center">
                <button
                  className="pagination-button"
                  onClick={goToPreviousPage}
                  disabled={currentpage === 1}
                >
                  Previous
                </button>
                <span>
                  {" "}
                  Page {currentpage} of {totalPages}{" "}
                </span> 
                <button
                  className="pagination-button"
                  onClick={goToNextPage}
                  disabled={currentpage === totalPages}
                >
                  Next
                </button>
              </div> */}
              </div>
            </section>
            <section>
              <div
                className="row custom-py-69 app-details align-items-center"
                id="showAllSection"
              >
                <div className="col-12 col-md-6 mb-3">
                  <h3 className="midium-text">Let’s play pickleball</h3>
                  <p className="mutated-text-20">
                    Organize and find local pickleball games. Connect with
                    fellow pickleball enthusiasts, enhance your playing skills,
                    or enjoy a friendly Pickleball match. Set up, join, and play
                    pickleball in your area or any searched location. Discover,
                    connect, and play with Pickleball Match.
                  </p>
                  <a href="https://apps.apple.com/us/app/pickleball-match/id6451394447">
                    <img
                      src="/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                      width="200"
                    />
                  </a>
                  {/* <div className="d-flex "> */}
                  {/* <div>
                    <img src="/images/QR.png" style={{ width: "100px" }} />
                  </div> */}
                  {/* <div className="ms-4">
                    
                  </div> */}
                  {/* </div> */}
                </div>
                <div className="col-12 col-md-6">
                  <div className="">
                    <img width="100%" src="/images/app-image.png" />
                    <img />
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      </div>
      <AdSenseAd key={"adsense2"} />
    </div>
  );
};

export default BodyContainer;
