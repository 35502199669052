// contexts/GoogleMapsContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from "react";
import { loadGoogleMapsAPI } from "../utils/googleMapsLoader";

interface GoogleMapsContextType {
  geocoder: google.maps.Geocoder | null;
}

export const GoogleMapsContext = createContext<GoogleMapsContextType>({
  geocoder: null,
});

interface GoogleMapsProviderProps {
  children: ReactNode;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({ children }) => {
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';
    
    loadGoogleMapsAPI(apiKey)
      .then(() => {
        if (window.google && window.google.maps) {
          setGeocoder(new window.google.maps.Geocoder());
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  }, []);

  return (
    <GoogleMapsContext.Provider value={{ geocoder }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};