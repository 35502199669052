// apiService.js
const apiUrl = process.env.REACT_APP_API_URL + '/ios/filteredEvents';

const eventsList = async (position: any, searchparam: any,page:number) => {
  console.log('currentlocation',position);
  console.log('serach',searchparam);
  try {
    if(searchparam !== '0,0'){
      position= searchparam;
      
    }
    else{
      
    }
    localStorage.setItem('position', position);
   
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
      body: JSON.stringify({
        position: position,
        // searchPosition: searchparam,
        "pageSize":18,
        "page":page,
        // Add other properties as needed
      }),
    };

    const response = await fetch(apiUrl, requestOptions);
    const result = await response.json();
    // console.log("asas",result);
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export { eventsList };
