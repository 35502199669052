// src/pages/home/index.tsx
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLocation } from "../../contexts/LocationContext";
import BodyContainer from "../../layout/BodyContainer";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import LocationPermissionComponent from "../../layout/Location";
import Search from "../../layout/Search";
import { GoogleMapsContext } from "../../contexts/GoogleMapsContext";

interface RouteParams {
  locationParam: string; // Even though it represents complex data, it's passed as a single encoded string
}

const Home = () => {
  const { geocoder } = useContext(GoogleMapsContext);
  const [searchData, setSearchData] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [userLocation, setUserLocation] = useState<any>(null);
  const [locationPermission, setLocationPermission] = useState<boolean | null>(
    null
  );
  const { selectedLocationSaved, setSelectedLocationSaved } = useLocation();
  // const { locationParam } = useParams(); // Use the interface here
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const handleLocationSearch = (location: {
    lat: number;
    lng: number;
    address: {
      label: string | number | boolean;
    };
  }) => {
    // Convert the label into a string for consistent processing
    let label = location.address.label.toString();

    // Replace spaces with dashes
    label = label.replace(/ /g, "-");

    // Replace commas with underscores
    label = label.replace(/,/g, "_");

    // Replace parentheses with square brackets
    label = label.replace(/\(/g, "[");
    label = label.replace(/\)/g, "]");

    // Encode the modified label into query parameters
    let queryParams = new URLSearchParams({
      label: label,  // Use the modified label
    }).toString();

    navigate(`/search-results?${queryParams}`);
  };

  const handleSearch = (data: string) => {
    // Handle the search data as needed
    setSearchData(data);
  };

  const handleSelectLocation = (location: any) => {
    // Handle the selected location data here
    console.log("Selected Location in index.tsx:", location);
    if (location) {
      setSelectedLocation(location);
      setSelectedLocationSaved(location);
      handleLocationSearch(location); // Now it also handles navigation
    }
  };

  const setUserPlace = (location: any) => {
    setUserLocation(location);
  };

  const fetchLatLongFromLabel = async (label: string): Promise<google.maps.GeocoderResult[]> => {
    if (!geocoder) return [];

    try {
      const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
        geocoder.geocode({ address: label }, (results, status) => {
          if (status === "OK" && results) {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });

      return results; // Return the geocoding results
    } catch (error) {
      console.error("Geocoding error:", error);
      return []; // Return an empty array on error
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      // Check if geolocation is supported by the browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User granted permission
          console.log("User granted location permission", position);
          setLocationPermission(true);
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            address: {
              label: "", // You can update this with reverse geocoding if needed
            },
          });
        },
        (error) => {
          // User denied permission or there was an error
          console.error("Error getting location:", error);
          setLocationPermission(false);
          setUserLocation({
            lat: 43.6532, // Toronto's approximate latitude
            lng: -79.3832, // Toronto's approximate longitude
            address: {
              label: "Toronto",
            },
          });
        }
      );
    } else {
      // Geolocation is not supported
      console.error("Geolocation is not supported by your browser.");
      setLocationPermission(false);
      setUserLocation({
        lat: 43.6532, // Toronto's approximate latitude
        lng: -79.3832, // Toronto's approximate longitude
        address: {
          label: "Toronto",
        },
      });
    }
  }, []); // Empty dependency array, runs only once when the component mounts

  useEffect(() => {
    let label = searchParams.get("label");

    // Perform reverse replacements if the label is present
    if (label) {
      // Replace dashes with spaces
      label = label.replace(/-/g, " ");

      // Replace underscores with commas
      label = label.replace(/_/g, ",");

      // Replace square brackets with parentheses
      label = label.replace(/\[/g, "(");
      label = label.replace(/\]/g, ")");
    }

    const fetchLocationData = async () => {
      if (label) {
        const geocodeResults = await fetchLatLongFromLabel(label.trim());
        
        if (geocodeResults.length > 0) {
          const { lat, lng } = geocodeResults[0].geometry.location;
          const newLocation = {
            lat: lat(),
            lng: lng(),
            address: { label: label.trim() },
          };

          if (
            !selectedLocation ||
            selectedLocation.lat !== newLocation.lat ||
            selectedLocation.lng !== newLocation.lng ||
            selectedLocation.address.label !== newLocation.address.label
          ) {
            setSelectedLocation(newLocation);
            console.log("Location fetched from label:", newLocation);
          }
        } else {
          console.error("No results found for label:", label);
        }
      } else if (selectedLocationSaved) {
        if (
          !selectedLocation ||
          selectedLocation.lat !== selectedLocationSaved.lat ||
          selectedLocation.lng !== selectedLocationSaved.lng ||
          selectedLocation.address.label !== selectedLocationSaved.address.label
        ) {
          console.log("Using saved selected location:", selectedLocationSaved);
          setSelectedLocation(selectedLocationSaved);
          handleSelectLocation(selectedLocationSaved);
        }
      } else if (userLocation) {
        if (
          !selectedLocation ||
          selectedLocation.lat !== userLocation.lat ||
          selectedLocation.lng !== userLocation.lng ||
          selectedLocation.address.label !== userLocation.address.label
        ) {
          console.log("Using user’s current location:", userLocation);
          setSelectedLocation(userLocation);
        }
      }
    };

    fetchLocationData();
  }, [searchParams, selectedLocationSaved, userLocation, geocoder]); // Ensure geocoder is in the dependency

  return (
    <>
      <Header />
      <Search
        onSearch={handleSearch}
        onSelectLocation={handleSelectLocation}
        UserPlace={userLocation}
      />
      {locationPermission === null ? (
        <LocationPermissionComponent />
      ) : locationPermission === true ? (
        <>
          <BodyContainer
            searchData={searchData}
            selectedLocation={selectedLocation}
            UserPlace={userLocation}
            setUserPlace={setUserPlace}
          />
        </>
      ) : (
        // <div>Location permission denied or not available.</div>
        <>
          {/* <Search onSearch={handleSearch} onSelectLocation={handleSelectLocation} /> */}
          <BodyContainer
            searchData={searchData}
            selectedLocation={selectedLocation}
            UserPlace={userLocation}
            setUserPlace={setUserPlace}
          />
        </>
      )}
      <Footer />
    </>
  );
};

export default Home;
