// src/store/reducers/facilityManagementReducer.ts

import { GET_EVENT_BY_FACILITY } from "../../actionTypes";

const initialState = {
  facilities: [],
};

const facilityManagementReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case GET_EVENT_BY_FACILITY:
      return {
        ...state,
        facilities: action.payload,
      };
    default:
      return state;
  }
};

export default facilityManagementReducer;
