// AdSenseAd.tsx
import { Adsense } from "@ctrl/react-adsense";
import React from "react";
import "./adsense.css";

const AdSenseAd = () => {
  return (
    <Adsense
      className="ads"
      client="ca-pub-6679883058778420"
      slot="4203918329"
      style={{ display: "block" }}
      responsive="true"
    />
  );
};

export default AdSenseAd;
